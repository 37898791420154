import React, { useEffect, useState } from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ContentContainer from "../components/ContentContainer";
import AuthedRoute from "../components/AuthedRoute";
import axios from "axios";
import { connect } from "react-redux";
import {
	Button, Form,
	FormFeedback,
	FormGroup,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Label,
} from "reactstrap";
import LockIcon from "../images/icon-lock.svg";
import { navigate } from "gatsby";
import { logoutUser } from "../state/user";

const DeleteAccount = ({ dispatch, user }) => {

	const [input, setInput] = useState({ touched: false, valiation: false, password: "", error: false });

	useEffect(() => {
		const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)\S{8,}$/;
		setInput({ ...input, validation: passwordRegex.test(input.password) });
	}, [input.password]);

	const deleteAccount = async e => {
		e.preventDefault();
		try {
			await axios.post(`${ process.env.GATSBY_API_BASE_URL }/api/users/delete`, {
				username: user.email,
				password: input.password,
			});
			dispatch(logoutUser());
			navigate("/account-deleted");
		} catch (error) {
			setInput({ ...input, error: error.response.statusText })
		}
	};

	return (
		<AuthedRoute>
			<Layout>
				<SEO title="Account Löschen"/>
				<ContentContainer title="Account Löschen" subtitle="Schade, dass du gehen willst." half>
					<div className="text-center mw-75 d-block mx-auto">
						<h3 className="font-weight-bold">Passwort bestätigen</h3>
						<p className="mt-3 mb-4 color-subtext">Bitte bestätige noch einmal dein Passwort damit wir sicherstellen können, dass der Account zu dir gehört.</p>
					</div>
					<Form onSubmit={ deleteAccount } className="mw-50 d-block mx-auto">
						<FormGroup>
							<Label for="password">Password</Label>
							<InputGroup>
								<InputGroupAddon addonType="prepend">
									<InputGroupText className="round-left input-group-icon">
										<img src={LockIcon} alt="lock icon prefix" />
									</InputGroupText>
								</InputGroupAddon>
								<Input type="password" name="password" id="password" placeholder="********"
										className="round-right"
										value={ input.password }
										valid={ input.touched && input.valiation }
										invalid={ input.touched && !input.valiation }
										onChange={ e => setInput({ ...input, password: e.target.value }) } />
							</InputGroup>
							<FormFeedback valid>
								<span role="img" aria-label="thumbsup emoji">👍</span>
							</FormFeedback>
							<FormFeedback>
								Passwort unsicher! (8 Zeichen, min. 1 Buchstabe, min. 1 Zahl)
							</FormFeedback>
						</FormGroup>
						<p className="feedback-error">{ input.error }</p>
						<Button color="primary" block className="round mt-4 py-2">Account löschen</Button>
					</Form>
				</ContentContainer>
			</Layout>
		</AuthedRoute>
	);
};

export default connect(state => ({
	user: state.user.user,
}))(DeleteAccount);
